import Head from 'next/head';
import Gnb from '../components/common/nav/Gnb';
import '../styles/reset.scss';
import '../styles/globals.scss';
import '../styles/_variables.scss';
import 'public/static/fonts/fonts.css';
import 'react-photo-view/dist/react-photo-view.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-phone-input-2/lib/style.css';
import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { useStudentInfoObjectState, useStudentListState } from '@atoms/states';
import { useEventPropertiesStore } from '@store/common';
import { Slide } from 'react-toastify';
import { configureScope, Scope } from '@sentry/nextjs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { queryClientConfig } from 'queries/core/config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { appWithTranslation } from 'next-i18next';
import nextI18nConfig from '../next-i18next.config';
import { CustomToastContainer } from '@styles/common/toastStyle';
import CheckFillIcon from '@asset/svg/CheckFillIcon';
import CancelFillIcon from '@asset/svg/CancelFillIcon';
import InfoFillIcon from '@asset/svg/InfoFillIcon';
import { useUserInfoQuery } from '@apis/query/user';
import 'swiper/css';
import * as amplitude from '@amplitude/analytics-browser';
import { UserType } from '@asset/enum/UserEnum';
import { useCheckIsIncorrectBuildIdQuery } from '@apis/query/core';
import NotifyUpdateToast from '@components/common/toast/NotifyUpdateToast';
import { GlobalStyle } from '@styles/globalsStyles';

dayjs.extend(utc);
dayjs.extend(timezone);

function CheckLoginState({ children }) {
  const { data: user } = useUserInfoQuery();
  const { data: isIncorrectBuildId } = useCheckIsIncorrectBuildIdQuery({
    refetchInterval: 60000,
  });
  const router = useRouter();
  const [studentList] = useStudentListState();
  const [, setStudentInfoObject] = useStudentInfoObjectState();

  useEffect(() => {
    amplitude.init(
      process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
      user ? user.userId : null,
      {
        minIdLength: 1,
        defaultTracking: { pageViews: true },
      },
    );
    const identifyEvent = new amplitude.Identify();
    if (user) {
      identifyEvent.set('userId', user.userId);
      identifyEvent.set('timezone', user?.timezone);
      identifyEvent.set('language', user?.language);
      identifyEvent.set(
        user.type === UserType.TEACHER ? 'teacherId' : 'studentId',
        user.id,
      );

      configureScope((scope: Scope) => {
        scope.setUser({
          type: user.type,
          id: user.id.toString(),
          name: user.name,
          timezone: user.timezone,
          language: user.language,
        });
      });
    }
    amplitude.identify(identifyEvent);
    return () => {
      configureScope((scope: Scope) => scope.clear());
    };
  }, [user]);

  useEffect(() => {
    const _studentInfoObject = {};
    if (studentList) {
      if (studentList.myStudentList) {
        studentList.myStudentList.map(
          (studentInfo) =>
            (_studentInfoObject[`${studentInfo.id}`] = studentInfo),
        );
      }
      if (studentList.otherStudentList) {
        studentList.otherStudentList.map(
          (studentInfo) =>
            (_studentInfoObject[`${studentInfo.id}`] = studentInfo),
        );
      }
      setStudentInfoObject(_studentInfoObject);
    }
  }, [studentList]);

  return (
    <>
      {isIncorrectBuildId && <NotifyUpdateToast />}
      <section>
        {router.pathname.includes('account') ||
        router.pathname.includes('study-report') ||
        router.pathname === '/_error' ||
        router.pathname.includes('problem-solving') ||
        router.pathname.includes('/feedback/detail') ? (
          <></>
        ) : (
          <Gnb></Gnb>
        )}
        {children}
      </section>
    </>
  );
}

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(() => {
    return new QueryClient(queryClientConfig);
  });
  const router = useRouter();
  const { setProperties } = useEventPropertiesStore();

  useEffect(() => {
    const chartTooltip = document.querySelector('div#chartjs-tooltip');
    if (chartTooltip) {
      chartTooltip.remove();
    }

    setProperties({
      url: typeof window !== 'undefined' ? window.location.href : '',
      path: router.asPath,
    });
  }, [router.asPath]);

  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_CLIENT_ID}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0"
              />
              <link
                rel="shortcut icon"
                href="/common/logo/favicon.svg"
                type="image/x-icon"
              />
            </Head>
            <main>
              <CheckLoginState>
                <Component {...pageProps} />
              </CheckLoginState>
              <CustomToastContainer
                position="top-center"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
                icon={({ type }) => {
                  if (type === 'success') {
                    return <CheckFillIcon />;
                  } else if (type === 'info') {
                    return <CancelFillIcon />;
                  } else if (type === 'error') {
                    return <InfoFillIcon />;
                  }
                }}
              />
            </main>
            <style jsx>{`
              body {
                > div {
                  display: flex;
                  flex-direction: column;
                }
              }
            `}</style>
          </GoogleOAuthProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18nConfig);
