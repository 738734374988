export enum StudyPurpose {
  MAIN_STUDY = 'main_study',
  DESCRIPTION = 'description',
}

export enum IndexName {
  ALL = '전체',
}

export enum WeekDay {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum StudyReportType {
  DAILY_REPORT = 'DAILY_STUDY_RESULT',
  WEEKLY_REPORT = 'WEEKLY_STUDY_RESULT',
}
export enum RecipientType {
  PARENT = 'PARENT',
  STUDENT = 'STUDENT',
  UNKNOWN = 'UNKNOWN',
}

export enum CreditType {
  WEEKLY_ASSIGNMENT_PROGRESS_OVER_90 = 'WEEKLY_ASSIGNMENT_PROGRESS_OVER_90',
  WEEKLY_ELAPSED_TIME_OVER_15H = 'WEEKLY_ELAPSED_TIME_OVER_15H',
  PASS_WEEKLY_WRONG_TEST = 'PASS_WEEKLY_WRONG_TEST',
  DAILY_RANK_TOP_10 = 'DAILY_RANK_TOP_10',
  WEEKLY_RANK_TOP_10 = 'WEEKLY_RANK_TOP_10',
  MONTHLY_RANK_TOP_10 = 'MONTHLY_RANK_TOP_10',
  DAILY_ELAPSED_TIME_OVER_3H = 'DAILY_ELAPSED_TIME_OVER_3H',
  EXCELLENT_STUDY_ATTITUDE = 'EXCELLENT_STUDY_ATTITUDE',
  PERFECT_ATTENDANCE_MONTH = 'PERFECT_ATTENDANCE_MONTH',
  HIGH_SCORE_ON_SCHOOL_EXAM = 'HIGH_SCORE_ON_SCHOOL_EXAM',
  RAPID_SCORE_IMPROVEMENT = 'RAPID_SCORE_IMPROVEMENT',
  DAILY_ASSIGNMENT_PROGRESS_UNDER_80 = 'DAILY_ASSIGNMENT_PROGRESS_UNDER_80',
  LATE_OVER_30M = 'LATE_OVER_30M',
  ABSENCE_NOTICE_TODAY = 'ABSENCE_NOTICE_TODAY',
  UNEXCUSED_ABSENCE = 'UNEXCUSED_ABSENCE',
  NO_RESPONSE_OVER_20M = 'NO_RESPONSE_OVER_20M',
  NON_STUDY_ACTIVITY = 'NON_STUDY_ACTIVITY',
  INSINCERE_STUDY_ATTITUDE = 'INSINCERE_STUDY_ATTITUDE',
  INAPPROPRIATE_BEHAVIOR_OR_LANGUAGE = 'INAPPROPRIATE_BEHAVIOR_OR_LANGUAGE',
  FAIL_WEEKLY_WRONG_TEST = 'FAIL_WEEKLY_WRONG_TEST',
  ETC = 'ETC',
}

export enum Grade {
  PRIMARY1 = '초1',
  PRIMARY2 = '초2',
  PRIMARY3 = '초3',
  PRIMARY4 = '초4',
  PRIMARY5 = '초5',
  PRIMARY6 = '초6',
  MIDDLE1 = '중1',
  MIDDLE2 = '중2',
  MIDDLE3 = '중3',
  HIGH1 = '고1',
  HIGH2 = '고2',
  HIGH3 = '고3',
  NTH = 'N수생',
  ETC = '기타',
}
