import Link from 'next/link';
import Image from 'next/image';
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  ReactElement,
} from 'react';
import { usersLogOut } from '@apis/api/auth';
import {
  loginUserInfoState,
  selectedStudentIdAtom,
  useStudentInfoObjectState,
  useStudentListState,
} from '@atoms/states';
import { getStudentUserList } from '@apis/api/dashboard';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import * as amplitude from '@amplitude/analytics-browser';
import { useEventPropertiesStore } from '@store/common';
import { GnbStyles } from '@styles/common/gnbStyles';
import { useTranslation } from 'next-i18next';
import cookieCutter from 'cookie-cutter';
import { FilledHomeIcon } from '@asset/svg/HomeIcon';
import OpenedBookIcon from '@asset/svg/OpenedBookIcon';
import AccountBalanceIcon from '@asset/svg/AccountBalanceIcon';
import PersonIcon from '@asset/svg/PersonIcon';
import { FilledSchoolIcon } from '@asset/svg/SchoolIcon';
import { FilledCalendarTodayIcon } from '@asset/svg/CalendarTodayIcon';
import { FilledBarChartIcon } from '@asset/svg/BarChartIcon';
import { FilledTrophyIcon } from '@asset/svg/TrophyIcon';
import { MenuBoxStyle } from '@styles/common/box/menuBoxStyles';
import { StudentPage, TeacherPage } from '@asset/enum/page';
import {
  useSelectedStudentInfoQuery,
  useUserInfoQuery,
} from '@apis/query/user';
import { UserType } from '@asset/enum/UserEnum';
import {
  useMyClassFilteringQueryStore,
  useMyClassWeeklyGoalFilteringQueryStore,
} from '@store/filteringQueryStore';
import { STUDENT_STUDY_CONTENT_URL_PREFIX } from 'routes/studyContent';
import { MY_CLASS_MAIN_URL_PREFIX } from 'routes/common';
import _ from 'lodash';
import SelectStudentDropdown from '../dropDown/SelectStudentDropDown';
import { getLoginPageUrlObject } from 'routes/account';

interface MenuObj {
  url: string;
  title: string;
  urlKeyword: string[];
  pageType?: string;
  icon?: ReactElement;
}

const Gnb = () => {
  const router = useRouter();
  const [, setUser] = useRecoilState(loginUserInfoState);
  const [selectedStudentId, setSelectedStudentId] = useRecoilState(
    selectedStudentIdAtom,
  );
  const { data: user } = useUserInfoQuery();
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  const [studentList, setStudentList] = useStudentListState();
  const [, setStudentInfoObject] = useStudentInfoObjectState();

  const { resetUrlQueryObj: resetMyClassUrlQueryObj } =
    useMyClassFilteringQueryStore();
  const { resetUrlQueryObj: resetMyClassWeeklyGoalUrlQueryObj } =
    useMyClassWeeklyGoalFilteringQueryStore();

  const [pickedMenu, setPickedMenu] = useState(null);
  const { properties } = useEventPropertiesStore();
  const myPageBoxRef = useRef(null);

  const focusOutItemListHandler = (event) => {
    if (myPageBoxRef.current && !myPageBoxRef.current.contains(event.target)) {
      setOpenMyPageBox(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', focusOutItemListHandler);
    return () => document.removeEventListener('click', focusOutItemListHandler);
  }, []);

  const { t, ready } = useTranslation('gnb');

  const studentMenu: MenuObj[] = [
    {
      url: '/dashboard',
      title: 'gnb:student.home',
      urlKeyword: [],
      pageType: 'main_dashboard',
      icon: <FilledHomeIcon color={'var(--gray-60)'} />,
    },
    {
      url: `/dashboard/study/${StudentPage.MY_ASSIGNMENTS}`,
      title: 'gnb:student.learn',
      urlKeyword: [
        StudentPage.MY_ASSIGNMENTS,
        StudentPage.MY_TESTS,
        StudentPage.MY_STUDY_MATERIALS,
        StudentPage.MY_FEEDBACKS,
        StudentPage.MY_QUESTIONS,
      ],
      pageType: 'my_assignments',
      icon: <FilledSchoolIcon color={'var(--gray-60)'} />,
    },
    {
      url: `/dashboard/plan/${StudentPage.MY_PLANS}`,
      title: 'gnb:student.plan',
      urlKeyword: [StudentPage.MY_PLANS, StudentPage.MY_SCHEDULE],
      pageType: 'my_plans',
      icon: <FilledCalendarTodayIcon color={'var(--gray-60)'} />,
    },
    {
      url: `/dashboard/analysis/${StudentPage.MY_STUDY_RECORDS}`,
      title: 'gnb:student.analytics',
      urlKeyword: [StudentPage.MY_STUDY_RECORDS],
      pageType: 'my_study_records',
      icon: <FilledBarChartIcon color={'var(--gray-60)'} />,
    },
    {
      url: `/dashboard/${StudentPage.MY_RANKING}`,
      title: 'gnb:student.ranking',
      urlKeyword: [StudentPage.MY_RANKING],
      pageType: 'ranking',
      icon: <FilledTrophyIcon color={'var(--gray-60)'} />,
    },
  ];

  const getTeacherMenu = (): MenuObj[] => {
    return [
      {
        url: `/manage/${TeacherPage.MY_CLASS}/main/`,
        title: 'gnb:teacher.manageStudent',
        urlKeyword: [
          'manage/dashboard',
          `manage/student-study-content`,
          `manage/student`,
          `manage/study-status-list/last`,
          `manage/study-status-list/total`,
          `manage/checking`,
          `manage/${TeacherPage.MY_CLASS}`,
        ],
        pageType: 'manage_student',
        icon: <PersonIcon color={'var(--gray-60)'} />,
      },
      {
        url: '/manage/material-list/?isHidden=false',
        title: 'gnb:teacher.manageTextbook',
        urlKeyword: ['/manage/material', '/manage/course'],
        pageType: 'manage_study_material',
        icon: <OpenedBookIcon color={'var(--gray-60)'} />,
      },
      {
        url: '/manage/teacher-list',
        title: 'gnb:teacher.management',
        urlKeyword: ['manage/teacher'],
        pageType: 'manage_teacher',
        icon: <AccountBalanceIcon color={'var(--gray-60)'} />,
      },
    ];
  };

  const [gnbMenu, setGnbMenu] = useState(
    user?.type === 'teacher'
      ? getTeacherMenu()
      : user?.type === 'student'
      ? studentMenu
      : [],
  );
  const [openMyPageBox, setOpenMyPageBox] = useState(false);

  const isPickedUrl = (element) => {
    if (element.url === pickedMenu) return true;
    else {
      for (let index = 0; index < element?.urlKeyword?.length; index++) {
        if (pickedMenu?.includes(element?.urlKeyword[index])) return true;
      }
    }
    return false;
  };

  useEffect(
    function () {
      if (router.isReady) {
        setPickedMenu(router.asPath);
        if (user?.type === UserType.TEACHER) {
          if (router.pathname.startsWith('/manage/dashboard/')) {
            const studentId = router.asPath.split('/')[4]
              ? parseInt(router.asPath.split('/')[4])
              : router.query.type
              ? parseInt(router.query.type[1])
              : selectedStudentId
              ? selectedStudentId
              : null;
            if (!_.isNaN(studentId) && !_.isNil(studentId)) {
              if (selectedStudentId !== studentId)
                setSelectedStudentId(studentId);
            } else {
              router.replace('/');
            }
          } else if (
            router.pathname.startsWith(STUDENT_STUDY_CONTENT_URL_PREFIX)
          ) {
            const studentId = router.query.studentId
              ? parseInt(router.query.studentId as string)
              : null;
            if (
              (!selectedStudent || selectedStudent?.id !== studentId) &&
              studentId
            )
              setSelectedStudentId(studentId);
          }
        }
      }
    },
    [router, router.isReady, user],
  );

  const getStudentListData = useCallback(async () => {
    if (user && user.type === UserType.TEACHER) {
      const studentListResponse = await getStudentUserList();
      setStudentList(studentListResponse);
    }
  }, [user]);

  const logOutEvent = async () => {
    await usersLogOut();
    await router.replace(getLoginPageUrlObject({}));
    amplitude.reset();
    setUser(null);
    setSelectedStudentId(null);
    setStudentList(null);
    setStudentInfoObject(null);
    resetMyClassUrlQueryObj();
    resetMyClassWeeklyGoalUrlQueryObj();
    cookieCutter.set('NEXT_LOCALE', '', {
      path: '/',
    });
  };

  useEffect(() => {
    if (user?.id) getStudentListData();
  }, [user?.id, getStudentListData]);

  useEffect(() => {
    setGnbMenu(
      user?.type === UserType.TEACHER
        ? getTeacherMenu()
        : user?.type === UserType.STUDENT
        ? studentMenu
        : [],
    );
  }, [user]);

  return (
    <div className="flex flex-col sticky top-0 z-[9999] main-nav">
      <GnbStyles userType={user?.type}>
        <div className="logo-box">
          <div className="object-container">
            <Link
              href={
                user?.type === 'teacher'
                  ? MY_CLASS_MAIN_URL_PREFIX
                  : `/dashboard`
              }
            >
              <a></a>
            </Link>
            <object
              data="/common/logo/gnb_white_logo.svg"
              type={'image/svg+xml'}
            />
          </div>
        </div>
        <ul className="menu-box">
          {gnbMenu.map((element) => {
            return ready ? (
              <Link key={element.url} href={element.url}>
                <a
                  onClick={() => {
                    let logText = '';
                    if (user?.type === 'teacher')
                      logText = `click_main_nav_${element?.pageType}`;
                    else {
                      logText = `click_student_main_nav_${element?.pageType}`;
                    }
                    amplitude.track(logText, properties);
                  }}
                  //TODO: 언어 설정 en일 경우 임시로 hidden처리
                  className={`${isPickedUrl(element) ? 'picked' : ''} ${
                    ['Leaderboard'].includes(t(element.title)) ? '!hidden' : ''
                  }`}
                >
                  {element.icon ?? element.icon}
                  {t(element.title)}
                </a>
              </Link>
            ) : (
              ''
            );
          })}
        </ul>

        <div className="account-box">
          {user?.type === UserType.TEACHER &&
            studentList?.myStudentList &&
            studentList?.otherStudentList && (
              <SelectStudentDropdown studentList={studentList} />
            )}
          {user?.type === UserType.STUDENT ? (
            <>
              <button
                className="student-profile"
                onClick={() => setOpenMyPageBox(!openMyPageBox)}
                ref={myPageBoxRef}
              >
                <Image src="/common/icon/user.svg" width={32} height={32} />
                {openMyPageBox ? (
                  <MenuBoxStyle
                    position="absolute"
                    top="2.5rem"
                    right="0"
                    width="max-content"
                  >
                    <span
                      onClick={() =>
                        router.push(
                          `/dashboard/my-page/${StudentPage.MY_INFORMATION}`,
                        )
                      }
                    >
                      {ready ? t('gnb:myInfo.myInfo') : ''}
                    </span>
                    <span
                      onClick={async () => {
                        await logOutEvent();
                      }}
                    >
                      {ready ? t('gnb:myInfo.logOut') : ''}
                    </span>
                  </MenuBoxStyle>
                ) : (
                  <></>
                )}
              </button>
            </>
          ) : (
            <button
              className="flex"
              onClick={async () => {
                await logOutEvent();
              }}
            >
              <Image
                className="gnb-icon"
                src="/common/nav/logout_white.svg"
                width={25}
                height={25}
              />
            </button>
          )}
        </div>
      </GnbStyles>
    </div>
  );
};

export default Gnb;
