import { SolvingResult } from '@asset/enum/problemSolvingEnum';
import { Course, StudyItemCountByMastery } from '@asset/type/course';
import { Mastery, MasteryCountInfo } from '@asset/type/mastery';
import _ from 'lodash';

export const getMasteryFromStudyHistory = (studyHistory): Mastery | 'empty' => {
  let mastery: Mastery | 'empty' = null;
  if (studyHistory.problemStatusDetails.length > 0) {
    const latestPsd = studyHistory.problemStatusDetails[0];
    const previousLastesPsd =
      studyHistory.problemStatusDetails.length < 2
        ? null
        : studyHistory.problemStatusDetails[1];

    if (latestPsd.solvingResult === SolvingResult.CORRECT) {
      if (
        !previousLastesPsd ||
        previousLastesPsd.solvingResult === SolvingResult.CORRECT
      ) {
        mastery = Mastery.VERY_HIGH;
      } else {
        mastery = Mastery.HIGH;
      }
    } else {
      if (
        !previousLastesPsd ||
        previousLastesPsd.solvingResult !== SolvingResult.CORRECT
      ) {
        mastery = Mastery.VERY_LOW;
      } else {
        mastery = Mastery.LOW;
      }
    }
  } else {
    mastery = 'empty';
  }
  return mastery;
};

export const getMissionMastery = (
  studyItemCountByMastery: StudyItemCountByMastery[],
): Mastery | null => {
  let mastery = Mastery.VERY_HIGH;
  let totalCounts = 0;
  let highCounts = 0;
  for (let i = 0; i < studyItemCountByMastery.length; i++) {
    const studyItem = studyItemCountByMastery[i];
    totalCounts += studyItem.count;
    if (
      studyItem.masteryLevel === Mastery.HIGH ||
      studyItem.masteryLevel === Mastery.VERY_HIGH
    ) {
      highCounts += studyItem.count;
    }
  }
  if (totalCounts > 0) {
    const highRate = (highCounts / totalCounts) * 100;
    if (80 <= highRate && highRate < 100) {
      mastery = Mastery.HIGH;
    } else if (40 <= highRate && highRate < 80) {
      mastery = Mastery.LOW;
    } else if (highRate < 40) {
      mastery = Mastery.VERY_LOW;
    }
    return mastery;
  }
  return null;
};

// TODO 확장성 측면에서 좋지 않다. Matery enum과 좀 더 밀접하게 결합할 수 있는 형태가 좋을 듯.
const masterySequence = [
  null,
  Mastery.VERY_LOW,
  Mastery.LOW,
  Mastery.HIGH,
  Mastery.VERY_HIGH,
];

// TODO true(+) / false(-) / null(불변)이라는 임의적 매핑을 사용하기보다는 별도의 enum을 활용하는 편이 나을 듯.
//  ex) IMPROVED / WORSENED / STATUS_QUO
export const isMasteryImprove = (
  pastMissionMastery: Mastery,
  presentMissionMastery: Mastery,
): boolean | null => {
  if (!_.isNil(presentMissionMastery)) {
    if (
      masterySequence.indexOf(presentMissionMastery) -
        masterySequence.indexOf(pastMissionMastery) >
      0
    ) {
      return true;
    } else if (
      masterySequence.indexOf(presentMissionMastery) -
        masterySequence.indexOf(pastMissionMastery) <
      0
    ) {
      return false;
    }
  }
  return null;
};

export const calculateChangeAmountOfMissionMastery = (
  pastCourse: Course,
  presentCourse: Course,
): { increase: number; decrease: number } => {
  let increase = 0;
  let decrease = 0;
  pastCourse.myModuleList.map((m, moduleIndex) =>
    m.myStudyResourceList.map((s, studyResourceIndex) => {
      const pastMissionMastery = getMissionMastery(
        s.masteryInfo.studyItemCounts,
      );
      const presentMissionMastery = getMissionMastery(
        presentCourse.myModuleList[moduleIndex].myStudyResourceList[
          studyResourceIndex
        ].masteryInfo.studyItemCounts,
      );
      const isImprove = isMasteryImprove(
        pastMissionMastery,
        presentMissionMastery,
      );
      if (!_.isNil(isImprove)) {
        isImprove ? (increase += 1) : (decrease += 1);
      }
    }),
  );
  return { increase, decrease };
};

export const getMasteryCounts = (
  masteryCountsInfo: {
    masteryLevel: Mastery;
    count: number;
  }[],
): MasteryCountInfo => {
  const masteryCounts = {
    [Mastery.VERY_HIGH]: 0,
    [Mastery.HIGH]: 0,
    [Mastery.LOW]: 0,
    [Mastery.VERY_LOW]: 0,
  };
  let totalMasteryCount = 0;
  masteryCountsInfo.map((m) => {
    masteryCounts[m.masteryLevel] = m.count;
    totalMasteryCount += m.count;
  });
  return { masteryCounts, totalMasteryCount };
};
