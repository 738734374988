export enum Mastery {
  VERY_HIGH = 'VERY_HIGH',
  HIGH = 'HIGH',
  LOW = 'LOW',
  VERY_LOW = 'VERY_LOW',
}
export const MasterySignatureColor = {
  [Mastery.VERY_HIGH]: 'var(--green-60)',
  [Mastery.HIGH]: 'var(--green-80)',
  [Mastery.LOW]: 'var(--yellow-70)',
  [Mastery.VERY_LOW]: 'var(--red-70)',
};
export interface MasteryCountInfo {
  masteryCounts: {
    [Mastery.VERY_HIGH]: number;
    [Mastery.HIGH]: number;
    [Mastery.LOW]: number;
    [Mastery.VERY_LOW]: number;
  };
  totalMasteryCount: number;
}
